<template>
    <div id="app" style="width: 100%; height: 100%">
        <div style="float: left">
            <el-row style="text-align: left; height: 100%">
                <el-col :span="12">
                    <el-menu
                        :default-active="defaultActive"
                        class="el-menu-vertical-demo"
                        @open="handleOpen"
                        @close="handleClose"
                        background-color="#545c64"
                        text-color="#fff"
                        unique-opened
                        active-text-color="#ffd04b"
                        router
                    >
                    <router-link to="/" style="text-align: center; color: #fff">
                        <p>云量</p>
                    </router-link>
                        <el-submenu index="1">
                            <template slot="title">
                                <i class="el-icon-location"></i>
                                <span>菜单</span>
                            </template>
                            <el-menu-item index="sku">商品管理</el-menu-item>
                        </el-submenu>

                        <el-submenu index="2">
                            <template slot="title">
                                <i class="el-icon-menu"></i>
                                <span>设备</span>
                            </template>
                            <el-menu-item>设备管理</el-menu-item>
                        </el-submenu>

                        <el-submenu index="3">
                            <template slot="title">
                                <i class="el-icon-document"></i>
                                <span>文档</span>
                            </template>
                            <el-menu-item index="elsa2_3">elsa2_3</el-menu-item>
                        </el-submenu>

                        <el-submenu index="4">
                            <template slot="title">
                                <i class="el-icon-setting"></i>
                                <span>设置</span>
                            </template>
                            <el-menu-item index="permissionsManagement">权限管理</el-menu-item>
                            <el-menu-item index="users">用户管理</el-menu-item>
                            <el-menu-item index="personal">个人中心</el-menu-item>
                        </el-submenu>
                    </el-menu>
                </el-col>
            </el-row>
        </div>
        <div style="height: 6vh">
            <el-button icon="el-icon-s-fold" style="margin-top: 5px; float: left; margin-left: 10px; border: 0;" @click="redirectToLogin"></el-button>
            <div
                style="
                    margin: 0 auto;
                    height: 6vh;
                    float: right;
                    margin-right: 50px;
                "
            >
                <el-header style="margin-top: 20px">
                    <el-dropdown>
                        <i
                            class="el-icon-setting"
                            style="margin-right: 12px"
                        ></i>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item @click.native="logout">退出登录</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                    <span>{{ username ? username : '未登录' }}</span>
                </el-header>
            </div>
            <router-link to="/login"><img src="../assets/登录.png" alt=".." style="width: 25px;height: 25px;margin-top: 18px;float: right;"></router-link>
        </div>
            <router-view
                style="
                    width: 89.7%; /* 设置最大宽度为85% */
                    padding-top: 10px;
                    max-height: 93vh;
                    overflow-y: auto;
                    scrollbar-width: none; /* Firefox 隐藏滚动条 */

                "
            >
        </router-view>
    </div>
</template>
<script>
import { get,del } from "@/utils/request.js";
export default {
    data() {
        return {
            isCollapse: true,
            username: localStorage.getItem('username'), // 初始化为空
            
        };
    },
    mounted() {
        const menu = document.querySelector(
            ".el-menu-vertical-demo:not(.el-menu--collapse)"
        );
        const screenWidth = window.innerWidth;
        const menuWidth = screenWidth * 0.14; // 设置为屏幕宽度的20%
        menu.style.width = `${menuWidth}px`;

        const script = document.createElement('script');
        script.src = "http://8.141.81.165:8881/api/application/embed?protocol=http&host=8.141.81.165:8881&token=c76b47449cea1a1b";
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);
    },
    methods: {
        // 删除所有cookie
        deleteAllCookies() {
            document.cookie.split(';').forEach(function(cookie) {
                var eqPos = cookie.indexOf('=');
                var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
            });
            // 清空localStorage
            localStorage.clear();
        },
        redirectToLogin() {
            if (this.$route.path !== '/') {
                    this.$router.push('/');
                }
            },
        // 退出登录
        logout() {
            del("login")
                .then((result) => {
                    this.deleteAllCookies() 
                    this.$router.push("/login");
                    alert("您已退出登录");
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        handleOpen(key, keyPath) {
            console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
            console.log(key, keyPath);
        },
    },
    computed: {
        defaultActive: function () {
            return this.$route.path.replace("/", "");
        },
    },
};
</script>

<style>
.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 210px !important;
    height: 100vh; /* 设置高度为视口高度 */
}
.router-link-exact-active {
  text-decoration: none; /* 去掉下划线 */
}
</style>
