import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import { get } from '../utils/request.js'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: HomeView,
    children: [
      {
        path: '', // 默认子路由
        name: '', // 移除父路由的名称
        component: () => import('../components/index.vue')
      },
      {
        path: 'permissionsManagement',
        name: 'permissionsManagement',
        component: () => import('../components/permissionsManagement.vue')
      },
      {
        path: 'sku',
        name: 'sku',
        component: () => import('../components/sku_info.vue')
      },
      {
        path: 'users',
        name: 'users',
        component: () => import('../components/users.vue')
      },
      {
        path: 'personal',
        name: 'personal',
        component: () => import('../components/personalCenter.vue')
      },
      {
        path: 'elsa2_3',
        name: 'elsa2_3',
        component: () => import('../components/elsa2_3.vue')
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/RegisterView.vue')
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../components/404.vue')
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})
// 导航守卫，用于检查用户是否已登录,如果未登录则跳转到登录页
router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem('access_token');
  if (to.name !== 'login' && !isAuthenticated) {
    next('/login');
  } else if (to.name === 'login') {
    next();
  } else if (to.name === '') {
    next();
  } else if (to.name === '404') {
    next();
  } else {
    get('check-permission', {
      route: to.path
    })
    .then(response => {
      if (response.data.hasPermission) {
        next();
      } else {
        // 使用Vue的异步组件来显示弹窗
        next(from.path);
        alert('没有权限访问该页面')
      }
    })
    .catch(error => {
      console.error(error);
      next('/login');
    });
  }
});

export default router